import Highcharts from 'highcharts';

const getLabelPrefix = (chartData) => {
    switch (chartData.unit) {
        case 'dollar':
            return '$';
        default:
            return '';
    }
};
const getLabelSuffix = (chartData) => {
    switch (chartData.unit) {
        case 'percent':
            return '%';
        default:
            return '';
    }
};
const getyAxisFormat = (chartData, value) => {
    return `${getLabelPrefix(chartData)}${Highcharts.numberFormat(value, chartData.format === 'integer' ? 0 : chartData.decimalPlaces || 0, chartData.format === 'integer' ? undefined : '.', ',')}${getLabelSuffix(chartData)}`;
};

const getDatasetMinValue = (chartData) => {
    const values = chartData.data.datasets
        .reduce((prev, curr) => {
            return [...prev, ...curr.data];
        }, [])
        .filter((v) => typeof v === 'number');

    const min = Math.min(...values);
    return min;
};

const getToolTipFormat = (chartData, ctx) => {
    return `<div style="display:flex; flex-direction:column">
                        <div style="font-size:1rem;font-weight:bold">${ctx.key}</div>
                        <div style="display:flex; flex-direction:row;">
                            <span style="font-weight:bold;padding:0">${ctx.series.name}: </span><span style="padding:0 0 0 0.25rem"><b>${getyAxisFormat(chartData, ctx.y)}</b></span>
                        </div>
                    </div>`;
};
export const getBarChartOptions = (chartData) => {

    let convertedData;
    convertedData = {};

    if (chartData.data.datasets.length === 0) return {};

    const min = getDatasetMinValue(chartData);
    convertedData.title = chartData.data.datasets[0].label;
    convertedData.subtitle = undefined;
    const categories = chartData.data.labels.map((l) => "" + l);
    convertedData.series = [
        {
            type: 'column',
            name: convertedData.title,
            data: chartData.data.datasets[0].data,
        },
    ];
    convertedData.yAxis = {
        min: min < 0 ? min : 0,
        title: {
            text: undefined,
        },
        labels: {
            formatter: function () {
                return getyAxisFormat(chartData, this.value);
                // return `${chartData.unit === 'dollar' ? '$' : ''}${this.axis.defaultLabelFormatter.call(this)}`;
            },
        },
    };
    return {
        lang: {
            decimalPoint: '.',
            thousandsSep: ',',
        },
        chart: {
            type: 'column',
        },
        title: {
            text: convertedData.title,
        },
        subtitle: {
            text: convertedData.subtitle, // 'Source: ' + '<a href="https://www.ssb.no/en/statbank/table/08940/" ' + 'target="_blank">SSB</a>',
        },
        xAxis: {
            categories
        },
        yAxis: convertedData.yAxis,
        tooltip: {
            formatter: function () {
                return getToolTipFormat(chartData, this);
            },
            useHTML: true,
        },

        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0,
            },
        },
        series: convertedData.series,
        credits: {
            enabled: false,
        },
    };
};
export const getLineChartOptions = (chartData) => {
    if (chartData.data.datasets.length === 0) return {};
    let convertedData;
    convertedData = {};

    const min = getDatasetMinValue(chartData);

    convertedData.title = chartData.title;
    convertedData.subtitle = undefined;
    const categories = chartData.data.labels.map((l) => "" + l);
    convertedData.yAxis = {
        min: min < 0 ? min : 0,
        title: {
            text: undefined,
        },
    };
    convertedData.series = chartData.data.datasets.map((i) => {
        return {
            type: 'line',
            name: i.label,
            data: i.data.map((a) => (typeof a === 'object' ? 0 : a)),
            color: i.borderColor[0],
        };
    });

    return {
        lang: {
            decimalPoint: '.',
            thousandsSep: ',',
        },

        title: {
            text: convertedData.title,
        },

        subtitle: {
            text: convertedData.subtitle,
        },

        legend: {
            verticalAlign: 'top',
        },

        plotOptions: {
            series: {
                label: {
                    connectorAllowed: false,
                },
            },
        },

        series: convertedData.series,
        xAxis: {
            type: "category",
            categories
        },
        yAxis: convertedData.yAxis,
        tooltip: {
            formatter: function () {
                return getToolTipFormat(chartData, this);
            },
            useHTML: true,
        },
        credits: {
            enabled: false,
        },
    };
};
export const getPieChartOptions = (chartData) => {
    if (chartData.data.datasets.length === 0) return {};
    let convertedData;
    convertedData = {};
    convertedData.title = chartData.data.datasets[0].label;
    convertedData.subtitle = undefined;
    const labels = chartData.data.labels.slice(1);
    const data = chartData.data.datasets[0].data.slice(1);

    convertedData.series = {
        name: chartData.data.datasets[0].label,
        colorByPoint: true,
        type: 'pie',
        data: labels.map((i, index) => {
            return {
                name: i,
                y: data[index],
                type: 'pie',
            };
        }),
    };
    return {
        lang: {
            decimalPoint: '.',
            thousandsSep: ',',
        },
        chart: {
            type: 'pie',
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
        },
        title: {
            text: convertedData.title,
        },
        tooltip: {
            formatter: function () {
                return getToolTipFormat(chartData, this);
            },
            useHTML: true,
        },

        yAxis: convertedData.yAxis || {
            title: '',
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.y}',
                },
            },
        },
        series: [convertedData.series],
        credits: {
            enabled: false,
        },
    };
};

export const dashboardChartOptions = (chartData) => {
    const min = getDatasetMinValue(chartData);
    const series = chartData.data.datasets.map((s) => {
        const type = s.type === 'bar' || chartData.chartType === 'stacked-bar' || chartData.chartType === 'bar' ? 'column' : 'line';

        return {
            type,
            name: s.label,
            color: s.color,
            data: s.data,
        };
    });
    const categories = chartData.data.labels.map((l) => l);

    return {
        title: {
            text: undefined,
        },
        xAxis: {
            categories,
        },
        yAxis: {
            min: min < 0 ? min : 0,
            title: {
                text: undefined,
            },
            labels: {
                formatter: function () {
                    return getyAxisFormat(chartData, this.value);
                    // return `${getLabelPrefix(item)}${this.axis.defaultLabelFormatter.call(this)}`;
                },
            },
            stackedLabels: {
                enabled: chartData.chartType === 'stacked-bar',
            },
        },
        plotOptions:
            chartData.chartType === 'stacked-bar'
                ? {
                    column: {
                        stacking: 'normal',
                        dataLabels: {
                            enabled: false,
                        },
                    },
                }
                : undefined,
        legend: {
            verticalAlign: 'top',
            horizontalAlign: 'center',
            enabled: chartData.depVariable ? chartData.depVariable.length > 1 : false,
        },
        tooltip: {
            formatter: function () {
                return getToolTipFormat(chartData, this);
            },
            useHTML: true,
        },
        series,
        credits: {
            enabled: false,
        },
    };
};
