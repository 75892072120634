// @flow
'use strict';

import React, { useEffect, useRef, useState } from 'react';
import { getBarChartOptions, getLineChartOptions, getPieChartOptions } from '../../highCharts';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Reports from '../../api/reports';
import styles from './pinnedChart.scss';
import { useSelector } from 'react-redux';
import { getParticipatingSchools, getUser } from '../../redux/reducers';
import { toast } from 'react-toastify';

type PropTypes = {|
    chart: any,
    removeChart: (id: string) => any,
|};
type SchoolNamePropTypes = {|
    school: any,
|};

export default function PinnedChart(props: PropTypes) {
    const [barChartOptions, setBarChartOptions] = useState({});
    const [lineChartOptions, setLineChartOptions] = useState({});
    const [pieChartOptions, setPieChartOptions] = useState({});
    const [reportData, setReportData] = useState();
    const [chart, setChart] = useState(props.chart);
    const participatingSchools = useSelector(getParticipatingSchools);
    const [isLoading, setIsLoading] = useState(false);
    const barChart = useRef();
    const lineChart = useRef();
    const pieChart = useRef();
    const user = useSelector(getUser);

    useEffect(() => {
        (async () => {
            if (chart) {
                try {
                    setIsLoading(true);

                    const response = await Reports.getReportData(chart.school, chart.topicId, chart.filters);
                    setReportData(response);
                    let options;

                    if (response) {
                        switch (chart.filters.chartType) {
                            case 'bar':
                                options = getBarChartOptions(response);
                                setBarChartOptions(options);
                                break;
                            case 'line':
                                options = getLineChartOptions({ ...response, title: chart.topicName }); //topic.name
                                setLineChartOptions(options);
                                break;
                            case 'pie':
                                options = getPieChartOptions(response);
                                setPieChartOptions(options);
                                break;
                            default:
                                options = getBarChartOptions(response);
                                break;
                        }
                    }
                } catch (error) {
                    toast.error('Error Retrieving Pinned Chart(s)');
                } finally {
                    setIsLoading(false);
                }
            }
        })();
    }, [chart]);

    const getKeyFullName = function (key) {
        switch (key) {
            case 'chartType':
                return 'Chart Type';
            case 'years':
                return 'Year(s)';
            case 'enrollment':
                return 'Enrollment';
            case 'grades':
                return 'Grades';
            case 'regions':
                return 'Regions';
            case 'schoolTypes':
                return 'School Types';
        }
        return '';
    };

    const getKeyValue = function (key, filterNames) {
        switch (key) {
            case 'chartType':
            case 'years':
                return filterNames[key];
            case 'enrollment':
            case 'grades':
            case 'schoolTypes':
            case 'regions':
                return filterNames[key].join(', ');
        }
        return '';
    };
    const Filters = ({ chart }) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem' }}>
                {chart.filterNames ? (
                    <>
                        <span>Filters Applied:</span>
                        {Object.keys(chart.filterNames).map((key) => {
                            return (
                                <div key={key}>
                                    {getKeyFullName(key)}: <strong>{getKeyValue(key, chart.filterNames)}</strong>
                                </div>
                            );
                        })}
                    </>
                ) : (
                    <></>
                )}
            </div>
        );
    };
    const Remove = ({ chart, removeChart }) => {
        return (
            <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                    removeChart(chart._id);
                }}
            >
                Remove
            </div>
        );
    };

    const SchoolName = (props: SchoolNamePropTypes) => {
        return <div style={{ fontWeight: 'bold', fontSize: '1.25rem' }}>{props.school.name}</div>;
    };

    useEffect(() => {
        props.chart && setChart(props.chart);
    }, [props]);

    if (isLoading) return <div style={{ height: '400px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{/* <CircularProgress size={64} /> */}</div>;

    if (!reportData) {
        <div className={styles.vizWrapper}>
            {/* <div>Filters Applied {getFilterNames(chart.filters)}</div> */}
            <div className={styles.vizWrapper}>
                <div style={{ fontWeight: 'bold', fontSize: '1rem' }}>Error Retrieving Chart</div>
                {/* <Filters chart={chart}  /> */}
                <Remove chart={chart} removeChart={props.removeChart} />
            </div>
        </div>;
    }
    return (
        <div className={styles.vizWrapper}>
            {/* <div>Filters Applied {getFilterNames(chart.filters)}</div> */}
            <div className={styles.vizWrapper}>
                {reportData && reportData.data && chart.filters.chartType === 'bar' ? (
                    <>
                        {user.nboaAdmin && <SchoolName school={participatingSchools.find((p) => p._id === chart.school)} />}
                        <div style={{ fontWeight: 'bold', fontSize: '1rem' }}>{`${chart.topicName}`}</div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
                            <Filters chart={chart} />
                            <Remove chart={chart} removeChart={props.removeChart} />
                        </div>
                        <HighchartsReact highcharts={Highcharts} options={barChartOptions} ref={barChart} containerProps={{ className: styles.graphItem }} />
                    </>
                ) : null}
                {reportData && reportData.data && chart.filters.chartType === 'line' ? (
                    <>
                        {user.nboaAdmin && <SchoolName school={participatingSchools.find((p) => p._id === chart.school)} />}
                        <div style={{ fontWeight: 'bold', fontSize: '1rem' }}>{chart.topicName}</div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
                            <Filters chart={chart} />
                            <Remove chart={chart} removeChart={props.removeChart} />
                        </div>
                        <HighchartsReact highcharts={Highcharts} options={lineChartOptions} ref={lineChart} containerProps={{ className: styles.graphItem }} />
                    </>
                ) : null}
                {reportData && reportData.data && chart.filters.chartType === 'pie' ? (
                    <>
                        {user.nboaAdmin && <SchoolName school={participatingSchools.find((p) => p._id === chart.school)} />}
                        <div style={{ fontWeight: 'bold', fontSize: '1rem' }}>{chart.topicName}</div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
                            <Filters chart={chart} />
                            <Remove chart={chart} removeChart={props.removeChart} />
                        </div>
                        <HighchartsReact highcharts={Highcharts} options={pieChartOptions} ref={pieChart} containerProps={{ className: styles.graphItem }} />
                    </>
                ) : null}
            </div>
        </div>
    );
}
