//@flow
'use strict';

import { lazy } from 'react';

const DataEntry = lazy(() => import('../views/DataEntry')); // DONE
const DataDictionary = lazy(() => import('../views/DataDictionary')); // DONE
const PeerGroups = lazy(() => import('../views/PeerGroups')); // DONE
const HighChartReports = lazy(() => import('../views/Reports')); // DONE
// const Reports = lazy(() => import('../views/Reports-pre-highcharts'));
const PivotTable = lazy(() => import('../views/PivotTable')); // DONE
const Permissions = lazy(() => import('../views/Permissions'));
const ScrubberAdmin = lazy(() => import('../views/ScrubberAdmin'));
const Outliers = lazy(() => import('../views/Outliers')); // DONE
const DataTables = lazy(() => import('../views/DataTables')); // DONE
const SchoolStatus = lazy(() => import('../views/SchoolStatus')); // DONE
const Faq = lazy(() => import('../views/Faq')); // DONE
const DataPointSectionEditor = lazy(() => import('../views/DataPointSectionEditor')); // DONE
const Wizard = lazy(() => import('../views/Wizard')); // DONE
const DataPointEditor = lazy(() => import('../views/DataPointEditor')); // DONE
const ReportTopicEditor = lazy(() => import('../views/ReportTopicEditor')); // DONE
const Dashboards = lazy(() => import('../views/Dashboards')); // DONE
// const DashboardsPreHighCharts = lazy(() => import('../views/Dashboards-pre-highcharts'));
const Explorer = lazy(() => import('../views/DataExplorer')); // DONE
const ReportPage = lazy(() => import('../cubejs/pages/ReportPage'));
const BehaviorPage = lazy(() => import('../cubejs/pages/BehaviorPage'));
const HeatMap = lazy(() => import('../views/HeatMap')); // DONE
const BulkExport = lazy(() => import('../views/BulkExport')); // DONE
const HeatMapData = lazy(() => import('../views/HeatMapData')); // DONE
const CategoryReports = lazy(() => import('../views/CategoryReports')); // DONE
const HomepageAdmin = lazy(() => import('../views/HomepageAdmin')); // DONE

const Routes: Array<RouteType> = [
    {
        path: '/dataEntry',
        fullPath: '/dataEntry/:filter*',
        secId: 'Enter Data',
        allowedUserTypes: ['admin', 'associationAdmin'],
        component: DataEntry,
    },
    {
        path: '/reporting',
        secId: 'Reporting Tools',
        participating: true,
        allowedUserTypes: ['schoolUser', 'admin', 'schoolAdmin', 'scrubber', 'asccociationUser', 'associationAdmin'],
        subRoutes: [
            {
                component: PeerGroups,
                path: '/peerGroups',
                id: 'Peer Groups',
                allowedUserTypes: ['schoolUser', 'admin', 'schoolAdmin', 'asccociationUser', 'associationAdmin'],
                hideSelectSchool: true,
            },
            {
                component: HighChartReports,
                path: '/charts',
                id: 'Charts',
            },
            // {
            //     component: Reports,
            //     path: '/charts-legacy',
            //     id: 'Charts (Legacy)',
            //     allowedUserTypes: ['admin'],
            // },
            {
                component: DataTables,
                path: '/dataTables',
                id: 'Calculated Metrics',
            },
            {
                component: PivotTable,
                path: '/pivotTable',
                id: 'Pivot Table',
                allowedUserTypes: ['admin'],
            },
            {
                component: Outliers,
                path: '/outliers',
                id: 'Outliers',
                allowedUserTypes: ['admin'],
                hideSelectSchool: true,
            },
            {
                component: Explorer,
                path: '/explorer',
                id: 'Explorer',
                allowedUserTypes: ['admin'],
                hideSelectSchool: true,
            },
        ],
    },
    {
        path: '/resources',
        secId: 'Resources',
        allowedUserTypes: ['schoolUser', 'admin', 'schoolAdmin', 'asccociationUser', 'associationAdmin'],
        hideSelectSchool: true,
        subRoutes: [
            {
                component: DataDictionary,
                path: '/dataDictionary',
                id: 'Data Dictionary',
            },
            {
                component: Faq,
                path: '/faq',
                id: 'FAQ',
            },
            {
                component: Wizard,
                path: '/wizard',
                id: 'Wizard',
            },
        ],
    },
    {
        path: '/schoolAdmin',
        secId: 'School Admin',
        allowedUserTypes: ['schoolAdmin', 'admin', 'associationAdmin'],
        hide: true,
        subRoutes: [
            {
                component: Permissions,
                path: '/permissions',
                id: 'User Permissions',
                hideSelectSchool: true,
            },
            {
                component: HeatMap,
                path: '/heatmap',
                id: 'Financial Sustainability Heat Map',
                props: {
                    type: 'school',
                },
                hideSelectSchool: false,
            },
        ],
    },
    {
        path: '/dashboards',
        secId: 'Dashboards',
        nboaAdmin: true,
        allowedUserTypes: ['schoolUser', 'admin', 'schoolAdmin', 'asccociationUser', 'associationAdmin'],
        subRoutes: [
            {
                path: '/cfi',
                id: 'CFI Calculator',
                component: Dashboards,
            },
            {
                path: '/financial',
                id: 'Financial Dashboard',
                component: Dashboards,
            },
            {
                path: '/heatmap',
                id: ' Financial Sustainability Heat Map',
                component: HeatMapData,
            },
            {
                component: Dashboards,
                path: '/nysais-financial',
                id: 'Financial Dashboard – NYSAIS',
                allowedUserTypes: ['admin', 'asccociationUser', 'associationAdmin'],
            },
        ],
    },
    {
        path: '/admin',
        secId: 'Admin',
        nboaAdmin: true,
        hideSelectSchool: true,
        allowedUserTypes: ['admin'],
        subRoutes: [
            {
                component: HomepageAdmin,
                path: '/homepageadmin',
                id: 'Homepage',
            },
            {
                path: '/status',
                id: 'School Status',
                component: SchoolStatus,
            },
            {
                path: '/content',
                id: 'Content',
                subRoutes: [
                    {
                        component: DataPointEditor,
                        path: '/dataPointEditor',
                        id: 'Data Points',
                    },
                    {
                        component: DataPointSectionEditor,
                        path: '/dataPointSectionEditor',
                        id: 'Sections',
                    },
                    {
                        component: ReportTopicEditor,
                        path: '/reportTopicEditor',
                        id: 'Chart Types',
                    },
                ],
            },
            {
                component: Permissions,
                path: '/users',
                id: 'Users',
            },
            {
                component: ScrubberAdmin,
                path: '/ScrubberAdmin',
                id: 'Scrubbing',
            },
            {
                component: HeatMap,
                path: '/heatmap',
                id: 'Heat Map',
            },
            {
                component: BulkExport,
                path: '/bulkexport',
                id: 'Bulk Export',
            },
            {
                component: CategoryReports,
                path: '/revandexp',
                id: 'Revenues & Expenses',
            },
        ],
    },
    {
        path: '/analytics',
        secId: 'Analytics',
        nboaAdmin: true,
        hideSelectSchool: true,
        allowedUserTypes: ['admin'],
        subRoutes: [
            {
                path: '/behavior',
                id: 'Bahavior',
                component: ReportPage,
                props: {
                    report: BehaviorPage,
                },
            },
        ],
    },
];

export const routesForUser: (user: ?UserType, selectedSchool: ?SchoolType) => Array<RouteType> = (user, selectedSchool) => {
    let userType;
    if (user) {
        if (user.nboaAdmin) {
            userType = 'admin';
        } else if (user.primaryContact) {
            userType = 'schoolAdmin';
        } else if (user.dataCommittee) {
            userType = 'scrubber';
        } else if (user.associationUser) {
            userType = 'asccociationUser';
        } else if (user.associationAdmin) {
            userType = 'associationAdmin';
        } else {
            userType = 'schoolUser';
        }
    }

    let abops = false;
    if (selectedSchool && selectedSchool.isABOPS) {
        abops = true;
    }
    return Routes.filter(function (route) {
        if (!userType) {
            return false;
        }
        if (route.path === '/admin' && user && (user.permissions.indexOf('assignDataToScrubbers') !== -1 || user.nboaAdmin)) {
            return true;
        }
        if (route.secId === 'reporting' && user && user.dataCommittee && user.associationUser && user.associationAdmin) {
            return true;
        }
        return route.allowedUserTypes.indexOf(userType) !== -1;
    }).map(function (route) {
        if (route.subRoutes) {
            route.subRoutes = route.subRoutes.filter(function (subRoute) {
                if (subRoute.allowedUserTypes && subRoute.allowedUserTypes.indexOf(userType) === -1) {
                    return false;
                }
                if (subRoute.isABOPS && !abops) {
                    return false;
                }
                if (subRoute.id === 'Outliers' && user && !user.nboaAdmin && !user.dataCommittee) {
                    return false;
                }
                if (selectedSchool && user && !user.nboaAdmin && !user.dataCommittee && route.path === '/reporting' && subRoute.id === 'FPS Ratios') {
                    return Array.isArray(selectedSchool.ratioYears) && selectedSchool.ratioYears.length > 0;
                }
                if (route.path === '/admin' && user && user.permissions.indexOf('assignDataToScrubbers') !== -1 && !user.nboaAdmin) {
                    return subRoute.id === 'Scrubbing' || subRoute.id === 'School Status';
                }
                return true;
            });
        }
        return route;
    });
};
