// @flow
'use strict';
import Api from '../../api';
import { setLoading, unsetLoading } from './';
import { getSelectedSchoolId, getSelectedReportTopicId, getSelectedReportFilters } from '../reducers';
import { fetchDataTable } from './dataTables';

import {
    FETCHING_REPORT_TOPICS,
    SET_REPORT_TOPICS,
    SET_REPORT_DATA,
    SET_REPORT_ERROR,
    RECEIVE_USER_DASHBOARD,
    SET_SELECTED_REPORT_FILTERS,
    SET_ALL_SCHOOL_REPORT_DATA,
    SET_RATIOS,
    SET_LOADING,
    UNSET_LOADING,
    FETCHING_REPORT_DATA,
    RESET_ALL_REPORT_DATA,
    RESET_REPORT_ERROR_MESSAGE,
    SET_FETCHING_RATIOS,
    RECEIVE_DASHBOARD,
    RESET_DASHBOARD,
    SET_SEARCH_FILTER,
    SET_FETCHING_OUTLIERS,
    SET_OUTLIERS,
    UPDATE_SINGLE_REPORTTOPIC,
    RECEIVE_REPORT_TOPIC_SECTIONS,
    FETCHING_REPORT_TOPIC_SECTIONS,
    SET_SELECTED_REPORT_TOPIC_SECTION_ID,
    FETCHING_REPORT_TOPICS_FOR_EDITING,
    SET_REPORT_TOPICS_FOR_EDITING,
    SET_DIALOG_SEARCH_TEXT,
    SET_DIALOG_SELECTED_REPORT_TOPIC_ID,
    SET_DIALOG_SELECTED_REPORT_SECTION_ID,
} from './constants';

export function setReportTopics(topics: Array<ReportTopicSectionType> | Array<ReportTopicType>, flattened?: boolean): any {
    if (flattened) {
        return {
            type: SET_REPORT_TOPICS,
            flattenedTopics: topics,
            flattened,
        };
    } else {
        return {
            type: SET_REPORT_TOPICS,
            topics,
            flattened,
        };
    }
}

export function setOutlierSearchField(searchFilter?: string): any {
    return {
        type: SET_SEARCH_FILTER,
        searchFilter,
    };
}

export function fetchTopics(schoolId?: string, flattened?: boolean): (dispatch: GenericDispatch) => void {
    return function (dispatch) {
        dispatch({
            type: FETCHING_REPORT_TOPICS,
            fetchingReportTopics: true,
        });
        dispatch({ type: SET_LOADING });
        Api.Reports.getTopics(schoolId, flattened)
            .then(function (topics) {
                if (flattened) {
                    dispatch(setReportTopics(topics, flattened));
                } else {
                    dispatch(setReportTopics(topics, flattened));
                }
            })
            .finally(function () {
                dispatch({ type: UNSET_LOADING });
            });
    };
}

export function fetchDataPointsForOutliers(year: YearNumberType): (dispatch: GenericDispatch) => any {
    return function (dispatch: GenericDispatch) {
        dispatch({ type: SET_LOADING });
        dispatch({
            type: SET_FETCHING_OUTLIERS,
            fetching: true,
        });
        return Api.Reports.getDataPointsForOutliers(year)
            .then(function (response) {
                dispatch(setOutliers(response.outliers || []));
            })
            .catch(function () {})
            .finally(function () {
                dispatch({ type: UNSET_LOADING });
                dispatch({
                    type: SET_FETCHING_OUTLIERS,
                    fetching: false,
                });
            });
    };
}

export function setOutliers(outliers: Array<OutlierDataPointSchoolsType>) {
    return {
        type: SET_OUTLIERS,
        outliers,
    };
}

export function setRatios(ratios: ReportRatiosResponseType) {
    return {
        type: SET_RATIOS,
        ratios,
    };
}

export function fetchRatiosForSchool(selectedSchoolId: string): (dispatch: GenericDispatch) => Promise<any> {
    return function (dispatch) {
        dispatch({ type: SET_LOADING });
        dispatch({
            type: SET_FETCHING_RATIOS,
            fetching: true,
        });
        return Api.Reports.getRatiosForSchool(selectedSchoolId)
            .then(function (ratios) {
                dispatch(setRatios(ratios));
            })
            .catch(function () {
                dispatch({
                    type: SET_FETCHING_RATIOS,
                    fetching: false,
                });
            })
            .finally(function () {
                dispatch({ type: UNSET_LOADING });
            });
    };
}

export function setSelectedReportTopicId(selectedReportTopicId: string): (dispatch: GenericDispatch, getState: () => ReduxStateType) => void {
    return function (dispatch, getState) {
        const state = getState();
        const currentSelectedFilters = getSelectedReportFilters(state);
        const currentTopicId = getSelectedReportTopicId(state);
        const schoolId = getSelectedSchoolId(state);
        if (schoolId) {
            dispatch(fetchReportData(schoolId, selectedReportTopicId, currentSelectedFilters, currentTopicId, undefined, false, true));
        }
    };
}

export function updateSelectedReportFilters(
    selectedFilters: SelectedReportFilterType,
    dataTableId?: string,
    selectedTopicId?: string,
    colaIndex?: string,
): (dispatch: GenericDispatch, getState: () => ReduxStateType) => void {
    return function (dispatch, getState) {
        const state = getState();
        const currentSelectedFilters = getSelectedReportFilters(state);
        const schoolId = getSelectedSchoolId(state);
        const topicId = selectedTopicId || getSelectedReportTopicId(state);
        const mergedFilter: SelectedReportFilterType = {
            ...currentSelectedFilters,
        };

        Object.keys(selectedFilters).forEach(function (key) {
            mergedFilter[key] = selectedFilters[key];
        });

        dispatch({
            type: SET_SELECTED_REPORT_FILTERS,
            selectedReportFilters: mergedFilter,
        });

        if (schoolId && topicId) {
            dispatch(fetchReportData(schoolId, topicId, mergedFilter, undefined, currentSelectedFilters, false, false));
        }

        if (dataTableId && schoolId) {
            dispatch(fetchDataTable(schoolId, dataTableId, mergedFilter, currentSelectedFilters, colaIndex));
        }
    };
}

export function setReportData(reportData: ReportDataType): any {
    return {
        type: SET_REPORT_DATA,
        reportData,
    };
}

export function setReportError(error: ReportErrorType): any {
    return {
        type: SET_REPORT_ERROR,
        error,
    };
}

export function resetReportErrorMessage(): ReduxEmptyActionType {
    return {
        type: RESET_REPORT_ERROR_MESSAGE,
    };
}

export function resetReporting(): ReduxEmptyActionType {
    return {
        type: RESET_ALL_REPORT_DATA,
    };
}

export function fetchReportData(
    schoolId: string,
    topic: string,
    selectedFilters: SelectedReportFilterType,
    originalTopicId: ?string,
    originalSelectedFilters: ?SelectedReportFilterType,
    doSetSelectedFilters: boolean,
    doSetSelectedTopic: boolean,
): (dispatch: GenericDispatch) => void {
    return function (dispatch) {
        dispatch({
            type: SET_LOADING,
        });
        dispatch({
            type: FETCHING_REPORT_DATA,
            fetchingReportData: true,
        });
        Api.Reports.getReportData(schoolId, topic, selectedFilters)
            .then(function (response) {
                dispatch(
                    setReportData({
                        ...response,
                        doSetSelectedFilters,
                        selectedFilters,
                        doSetSelectedTopic,
                        selectedTopicId: topic,
                    }),
                );
            })
            .catch(function (err) {
                dispatch(
                    setReportError({
                        errorMessage: err.message,
                        originalTopicId: originalTopicId,
                        originalSelectedFilters: originalSelectedFilters,
                    }),
                );
            })
            .finally(() => {
                dispatch({
                    type: UNSET_LOADING,
                });
            });
    };
}

export function fetchAllSchoolReportData(selectedTopicId: string): (dispatch: GenericDispatch) => void {
    return function (dispatch) {
        dispatch({
            type: SET_LOADING,
        });
        dispatch({
            type: FETCHING_REPORT_DATA,
            fetchingReportData: true,
        });
        Api.Reports.getAllSchoolReportData(selectedTopicId)
            .then(function (response) {
                dispatch({
                    type: SET_ALL_SCHOOL_REPORT_DATA,
                    allSchoolReportData: response,
                });
            })
            .catch(function () {})
            .finally(() => {
                dispatch({
                    type: UNSET_LOADING,
                });
            });
    };
}

export function fetchDashboard(dashboardId: string, schoolId: string, selectedPeergroup: ?string, colaIndex?: string): (dispatch: GenericDispatch) => void {
    return function (dispatch) {
        dispatch({
            type: SET_LOADING,
        });
        dispatch({
            type: FETCHING_REPORT_DATA,
            fetchingReportData: true,
        });
        Api.Dashboards.fetchDashboard(dashboardId, schoolId, selectedPeergroup, colaIndex)
            .then(function (dashboard) {
                dispatch({
                    type: RECEIVE_DASHBOARD,
                    dashboard,
                });
            })
            .finally(() => {
                dispatch({
                    type: UNSET_LOADING,
                });
                dispatch({
                    type: FETCHING_REPORT_DATA,
                    fetchingReportData: false,
                });
            });
    };
}

export function resetDashboard(): ReduxEmptyActionType {
    return {
        type: RESET_DASHBOARD,
    };
}

export function fetchUserDashboard(): (dispatch: GenericDispatch) => void {
    return function (dispatch) {
        dispatch({
            type: SET_LOADING,
        });
        dispatch({
            type: FETCHING_REPORT_DATA,
            fetchingReportData: true,
        });
        Api.Dashboards.fetchUserDashboard()
            .then(function (userDashboard) {
                dispatch({
                    type: RECEIVE_USER_DASHBOARD,
                    userDashboard,
                });
            })
            .finally(() => {
                dispatch({
                    type: UNSET_LOADING,
                });
                dispatch({
                    type: FETCHING_REPORT_DATA,
                    fetchingReportData: false,
                });
            });
    };
}

export function updateReportTopic(reportTopicId: string, update: { name?: string, definition?: string }): (dispatch: GenericDispatch, getState: () => ReduxStateType) => void {
    return function (dispatch) {
        dispatch(setLoading());
        Api.Reports.updateReportTopic(reportTopicId, update)
            .then(function (reportTopic) {
                dispatch({
                    type: UPDATE_SINGLE_REPORTTOPIC,
                    reportTopic,
                });
            })
            .finally(function () {
                dispatch(unsetLoading());
            });
    };
}

export function fetchReportTopicSections(): (dispatch: GenericDispatch) => void {
    return function (dispatch: GenericDispatch) {
        dispatch(setLoading());
        dispatch(setFetchingReportTopicSections);

        Api.Reports.getAllSections()
            .then(function (sections) {
                const sectionOrder = [];
                const sectionObject = {};
                sections.forEach(function (section) {
                    sectionOrder.push(section._id);
                    sectionObject[section._id] = section;
                });
                dispatch({
                    type: RECEIVE_REPORT_TOPIC_SECTIONS,
                    sections: sectionObject,
                    sectionOrder: sectionOrder,
                });
                return sections;
            })
            .finally(function () {
                dispatch(unsetLoading());
                dispatch(unsetFetchingReportTopicSections);
            });
    };
}

export function fetchReportTopicsForEditing(selectedSectionId?: string): (dispatch: GenericDispatch) => void {
    return function (dispatch: GenericDispatch) {
        dispatch(setLoading());
        dispatch(setFetchingReportTopicsForEditing());
        Api.Reports.getReportTopicsForEditing(selectedSectionId)
            .then(function (response) {
                // const reportTopics = {};
                // response.forEach((rt) => (reportTopics[rt._id] = rt));
                dispatch(receiveReportTopicsForEditing(response));
            })
            .catch(() => {
                dispatch(unsetFetchingReportTopicsForEditing());
                dispatch(unsetLoading());
            })
            .finally(function () {
                dispatch(unsetFetchingReportTopicsForEditing());
                dispatch(unsetLoading());
            });
    };
}

export function setFetchingReportTopicSections(): any {
    return {
        type: FETCHING_REPORT_TOPIC_SECTIONS,
        fetching: true,
    };
}
export function unsetFetchingReportTopicSections(): any {
    return {
        type: FETCHING_REPORT_TOPIC_SECTIONS,
        fetching: false,
    };
}

export function setFetchingReportTopics(): any {
    return {
        type: FETCHING_REPORT_TOPICS,
        fetching: true,
    };
}

export function unsetFetchingReportTopics(): any {
    return {
        type: FETCHING_REPORT_TOPICS,
        fetching: false,
    };
}
export function setFetchingReportTopicsForEditing(): any {
    return {
        type: FETCHING_REPORT_TOPICS_FOR_EDITING,
        fetching: true,
    };
}

export function unsetFetchingReportTopicsForEditing(): any {
    return {
        type: FETCHING_REPORT_TOPICS_FOR_EDITING,
        fetching: false,
    };
}
export function setReportTopicSectionId(sectionId: string): any {
    return {
        type: SET_SELECTED_REPORT_TOPIC_SECTION_ID,
        selectedReportTopicSectionId: sectionId,
    };
}

function receiveReportTopicsForEditing(reportTopics: { string: ReportTopicType }) {
    return {
        type: SET_REPORT_TOPICS_FOR_EDITING,
        topicsForEditing: reportTopics,
    };
}

export function setDialogSearchText(searchText: string): any {
    return {
        type: SET_DIALOG_SEARCH_TEXT,
        searchText,
    };
}
export function setDialogSelectedReportTopicId(topicId: string): any {
    return {
        type: SET_DIALOG_SELECTED_REPORT_TOPIC_ID,
        topicId,
    };
}
export function setDialogSelectedReportSectionId(sectionId: string): any {
    return {
        type: SET_DIALOG_SELECTED_REPORT_SECTION_ID,
        sectionId,
    };
}
