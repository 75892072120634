import Api from '../../api/index.jsx';
export const asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
};

export const getTopics = async (schoolId, flattened) => {
    const topics = await Api.Reports.getTopics(schoolId, flattened);

    return topics;
};

export const getReportData = async (schoolId, topic, selectedFilters) => {
    const reportData = await Api.Reports.getReportData(schoolId, topic, selectedFilters);
    return reportData;
};

export const getReportTopicSections = async () => {
    const sections = await Api.Reports.getAllSections();

    const sectionOrder = [];
    const sectionObject = {};
    await asyncForEach(sections, function (section) {
        sectionOrder.push(section._id);
        sectionObject[section._id] = section;
    });

    return { sections: sectionObject, sectionOrder: sectionOrder };
};

export const filterOrder = [
    { _id: 'chartType', order: 1, multiSelect: false },
    { _id: 'grouping', order: 2, multiSelect: false },
    { _id: 'regions', order: 3, multiSelect: true },
    { _id: 'states', order: 4, multiSelect: true },
    { _id: 'city', order: 5, multiSelect: true },
    { _id: 'grades', order: 6, multiSelect: true },
    { _id: 'schoolTypes', order: 7, multiSelect: true },
    { _id: 'enrollments', order: 7, multiSelect: true },
    { _id: 'years', order: 9, multiSelect: false },
    { _id: 'region', order: 3, multiSelect: true },
    { _id: 'state', order: 4, multiSelect: true },
    { _id: 'grade', order: 5, multiSelect: true },
    { _id: 'schoolType', order: 6, multiSelect: true },
    { _id: 'enrollment', order: 7, multiSelect: true },
    { _id: 'year', order: 8, multiSelect: false },
];
