import Homepage from '../api/homepage';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedSchool } from '../redux/reducers';
import { useHomepageContext } from '../context/homepage';
import { setAnnouncements, setDataEntryStatus, setHomepageItems, setIsHomepageLoading, setPinnedCharts, setQuickLinks } from '../context/ducks/homepage';
import { toast } from 'react-toastify';
import { setLoading, unsetLoading } from '../redux/actions';

const useHomepage = () => {
    const selectedSchool = useSelector(getSelectedSchool);
    const { dispatch } = useHomepageContext();
    const reduxDispatch = useDispatch();
    async function getUserHomepage() {
        try {
            reduxDispatch(setLoading());
            dispatch(setIsHomepageLoading(true));
            const response = await Homepage.fetchUserHomepage(selectedSchool ? selectedSchool._id : 'admin');

            dispatch(setAnnouncements(response.announcements));
            dispatch(setDataEntryStatus(response.dataEntryStatus));
            dispatch(setQuickLinks(response.quickLinks));
            dispatch(setPinnedCharts(response.pinnedCharts));
            dispatch(setHomepageItems(response.items));
        } catch (error) {
            toast.error('An Error Occured Retrieving Homepage');
        } finally {
            reduxDispatch(unsetLoading());
            dispatch(setIsHomepageLoading(false));
        }
    }

    return [getUserHomepage];
};

export default useHomepage;
