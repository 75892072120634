// @flow
'use strict';

// import styles from './pinnedChart.scss';

import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import PinnedChart from '../PinnedChart';
import ConfirmModal from '../ConfirmModal';
import Homepage from '../../api/homepage';
import { useHomepageContext } from '../../context/homepage';
import { removePinnedCharts } from '../../context/ducks/homepage';
import { getSelectedSchool } from '../../redux/reducers';
import { Constants } from '../../utils';
import { toast } from 'react-toastify';
import { StyledHomePageTyography } from '../../views/UserHomepage';
type PropTypes = {|
    charts: any[],
    isLoading: boolean,
|};

export default function PinnedCharts(props: PropTypes) {
    const { charts, isLoading } = props;
    const { dispatch } = useHomepageContext();
    const [selectedId, setSelectedId] = useState(null);
    const [loading, setLoading] = useState(isLoading);
    const selectedSchool = useSelector(getSelectedSchool);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [confirmDisabled, setConfirmDisabled] = useState(false);
    const removeChart = (id) => {
        setSelectedId(id);
        setIsModalOpen(true);
    };
    const handleClose = () => {
        setIsModalOpen(false);
        setSelectedId(null);
    };
    const handleConfirm = async () => {
        try {
            setConfirmDisabled(true);
            setLoading(true);
            await Homepage.deleteChart(selectedId);
            dispatch(removePinnedCharts(selectedId));

            setSelectedId(null);
            setIsModalOpen(false);
            toast.success('Chart Removed Successfully');
        } catch (error) {
            toast.error('An error occured.  Please contact administrator.');
        } finally {
            setIsModalOpen(false);
            setLoading(false);
            setConfirmDisabled(false);
        }
    };

    const PinMore = ({ charts }) => {
        return (
            charts &&
            charts.length > 0 && (
                <>
                    <Link to={`reporting/charts?school=${selectedSchool._id}&year=${Constants.currentYear}`}>
                        <span style={{ color: '#1976d2', textDecoration: 'underline' }}>Need To Pin More?</span>.
                    </Link>
                </>
            )
        );
    };
    useEffect(() => {
        setLoading(isLoading);
    }, [isLoading]);

    if (loading) return <div style={{ height: '400px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{/* <CircularProgress size={64} /> */}</div>;

    if (charts && charts.length === 0)
        return (
            <>
                <StyledHomePageTyography>{'Your Pinned Charts'}</StyledHomePageTyography>
                <div>
                    <Typography>
                        No charts are currently pinned. Pin up to 3{' '}
                        <Link to={`reporting/charts?school=${selectedSchool._id}&year=${Constants.currentYear}`}>
                            <span style={{ color: '#1976d2', textDecoration: 'underline' }}>charts</span>.
                        </Link>
                    </Typography>
                </div>
            </>
        );
    if (!charts) return <></>;
    return (
        <>
            <StyledHomePageTyography>{'Your Pinned Charts'}</StyledHomePageTyography>
            <div>
                <PinMore charts={charts} />
                <ConfirmModal isOpen={isModalOpen} title={'Remove Chart'} onClose={handleClose} onConfirm={handleConfirm} confirmDisabled={confirmDisabled}>
                    <div>Are you sure you want to remove this chart?</div>
                </ConfirmModal>
                {charts.map((chart, index) => {
                    return (
                        <div style={{ flex: '1' }} key={index}>
                            <PinnedChart chart={chart} removeChart={removeChart} />
                        </div>
                    );
                })}
            </div>
        </>
    );
}
