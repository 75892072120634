// @flow
'use strict';

import axios from 'axios';
import * as Utils from '../utils';
const Constants = Utils.Constants;

const Dashboards = {};

Dashboards.fetchUserDashboard = (): Promise<UserDashboardType> => {
    return axios
        .get(Constants.apiBaseUrl + 'dashboards/current', {
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No Dashboard returned from Api');
            }
            return response.data;
        });
};

Dashboards.fetchDashboard = (dashboardId: string, schoolId: string, selectedPeergroup: ?string, colaIndex?: string): Promise<DashboardType> => {
    const year = Constants.currentYear;
    const params = {
        year,
        school: schoolId,
        peergroup: selectedPeergroup,
        colaIndex,
    };
    return axios
        .get(Constants.apiBaseUrl + 'dashboards/' + dashboardId, {
            params,
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No Dashboard returned from Api');
            }
            let data = response.data;
            return {
                isFake: false,
                ...data,
            };
        })
        .catch(function (err) {
            if (err.response && err.response.data && err.response.data.message && err.response.data.message.indexOf('Missing CFI values') !== -1) {
                if (dashboardId === 'cfi') {
                    return {
                        isFake: true,
                        _id: 'cfi',
                        pages: [
                            {
                                title: 'Composite Financial Index (CFI) Summary',
                                rows: [
                                    {
                                        itemCount: 5,
                                        height: 'small',
                                        items: [
                                            {
                                                _id: '5beb1a1ad3dcef12f9379ae5',
                                                type: 'number',
                                                title: 'Primary Reserve Ratio',
                                                value: 0.6051856757828007,
                                                format: 'float',
                                                decimalPlaces: 2,
                                                unit: 'raw',
                                                min: 0,
                                            },
                                            {
                                                _id: '5bec3563a1cb7a3fecaddd91',
                                                type: 'number',
                                                title: 'Viability Ratio',
                                                value: 0.6747848958535112,
                                                format: 'float',
                                                decimalPlaces: 2,
                                                unit: 'raw',
                                                min: 0,
                                            },
                                            {
                                                _id: '5bec3563a1cb7a3fecaddd92',
                                                type: 'number',
                                                title: 'Return on Net Assets',
                                                value: 0.07546614291164865,
                                                format: 'float',
                                                decimalPlaces: 2,
                                                unit: 'percent',
                                                min: 0,
                                            },
                                            {
                                                _id: '5bec3563a1cb7a3fecaddd93',
                                                type: 'number',
                                                title: 'Net Operating Revenues',
                                                value: 0.09707206959800872,
                                                format: 'float',
                                                decimalPlaces: 2,
                                                unit: 'percent',
                                                min: 0,
                                            },
                                            {
                                                _id: '5bec5f71a1cb7a3fecaddda9',
                                                type: 'number',
                                                title: 'CFI',
                                                value: 3.660329759973742,
                                                format: 'float',
                                                decimalPlaces: 2,
                                                unit: 'raw',
                                                min: 0,
                                            },
                                        ],
                                    },
                                    {
                                        itemCount: 1,
                                        height: 'medium',
                                        style: {
                                            width: '50%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                        },
                                        items: [
                                            {
                                                _id: '5bed900f57266bf48ae4c471',
                                                type: 'graph',
                                                title: 'CFI Trend',
                                                subtitle:
                                                    'A single measure that demonstrates the financial health and sustainability, while still illustrating a holistic view of the institution based on four, weighted underlying ratios.',
                                                format: 'float',
                                                decimalPlaces: 2,
                                                unit: 'raw',
                                                chartType: 'mixed',
                                                min: 0,
                                                data: {
                                                    labels: ['2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018'],
                                                    datasets: [
                                                        {
                                                            label: 'CFI Trend',
                                                            data: [null, null, null, null, null, null, 4.662243486218149, 3.660329759973742],
                                                            type: 'bar',
                                                            color: '#1f78b4',
                                                        },
                                                        {
                                                            label: 'Distress',
                                                            data: [-3, -3, -3, -3, -3, -3, -3, -3],
                                                            type: 'line',
                                                            fill: false,
                                                            color: '#C03634',
                                                        },
                                                        {
                                                            label: 'Fragility',
                                                            data: [0, 0, 0, 0, 0, 0, 0, 0],
                                                            type: 'line',
                                                            fill: false,
                                                            color: '#ff7f00',
                                                        },
                                                        {
                                                            label: 'Stability',
                                                            data: [3, 3, 3, 3, 3, 3, 3, 3],
                                                            type: 'line',
                                                            fill: false,
                                                            color: '#33a02c',
                                                        },
                                                        {
                                                            label: 'Strength',
                                                            data: [6, 6, 6, 6, 6, 6, 6, 6],
                                                            type: 'line',
                                                            fill: false,
                                                            color: '#6a3d9a',
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        itemCount: 2,
                                        height: 'medium',
                                        items: [
                                            {
                                                _id: '5beed578fa12e190f09274ee',
                                                type: 'graph',
                                                title: 'Primary Reserve Ratio Trend',
                                                subtitle:
                                                    'The Primary Reserve Ratio provides a snapshot of strength and flexibility by indicating how long the school could financial cover expenses from reserves.\nA threshold value of .4 indicates 5 months of cash reserves.',
                                                format: 'float',
                                                decimalPlaces: 2,
                                                min: 0,
                                                unit: 'raw',
                                                chartType: 'mixed',
                                                data: {
                                                    labels: ['2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018'],
                                                    datasets: [
                                                        {
                                                            label: 'PRR',
                                                            data: [
                                                                1.0714374004191733, 0.8336119179266103, 0.9693476596053139, 0.9707100838500312, 0.745154120038476, 0.5340327035863994,
                                                                0.7811579780221426, 0.6051856757828007,
                                                            ],
                                                            type: 'bar',
                                                            color: '#1f78b4',
                                                        },
                                                        {
                                                            label: 'Threshold',
                                                            data: [0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4],
                                                            type: 'line',
                                                            fill: false,
                                                            color: '#ff7f00',
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                _id: '5beed57ffa12e190f09274ef',
                                                type: 'graph',
                                                title: 'Viability Ratio Trend',
                                                subtitle:
                                                    "The Viability Ratio measures the availability of resources to balance debt. It is an indication of debt capacity and may influence the school's ability to issue new debt.\nA threshold value of 1.25 ensures resource flexibility.",
                                                format: 'float',
                                                decimalPlaces: 2,
                                                unit: 'raw',
                                                min: 0,
                                                chartType: 'mixed',
                                                data: {
                                                    labels: ['2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018'],
                                                    datasets: [
                                                        {
                                                            label: 'VR',
                                                            data: [
                                                                0.8229652278237326, 0.6770678675228373, 0.7921429584826103, 0.8912425725227024, 0.7551613862005213, 0.5344518990796245,
                                                                0.8248523962903269, 0.6747848958535112,
                                                            ],
                                                            type: 'bar',
                                                            color: '#1f78b4',
                                                        },
                                                        {
                                                            label: 'Threshold',
                                                            data: [1.25, 1.25, 1.25, 1.25, 1.25, 1.25, 1.25, 1.25],
                                                            type: 'line',
                                                            fill: false,
                                                            color: '#ff7f00',
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        itemCount: 2,
                                        height: 'medium',
                                        items: [
                                            {
                                                _id: '5beed839fa12e190f09274f0',
                                                type: 'graph',
                                                title: 'Return on Net Assets Ratio Trend',
                                                subtitle:
                                                    'The Return on Net Assets Ratio provides a comprehensive measure of the growth or decline in total wealth, based on the return generated on net assets owned.\nA threshold value of 6% ensures 3-4% after inflation.',
                                                format: 'float',
                                                decimalPlaces: 3,
                                                unit: 'raw',
                                                min: 0,
                                                chartType: 'mixed',
                                                data: {
                                                    labels: ['2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018'],
                                                    datasets: [
                                                        {
                                                            label: 'RNAR',
                                                            data: [
                                                                0.04154206220557372, -0.10236644558449906, 0.08869134489774176, 0.12239739425192907, -0.06410715775325397, -0.11684293346536301,
                                                                0.1461802102968754, 0.07546614291164865,
                                                            ],
                                                            type: 'bar',
                                                            color: '#1f78b4',
                                                        },
                                                        {
                                                            label: 'Threshold',
                                                            data: [0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06],
                                                            type: 'line',
                                                            fill: false,
                                                            color: '#ff7f00',
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                _id: '5beed83bfa12e190f09274f1',
                                                type: 'graph',
                                                title: 'Net Operating Revenues Ratio Trend',
                                                subtitle:
                                                    "The Net Operating Revenues Ratio indicates a surplus or deficit generated by the school's operations. A threshold value of 4% is used with no operating measure.",
                                                format: 'float',
                                                min: 0,
                                                decimalPlaces: 2,
                                                unit: 'raw',
                                                chartType: 'mixed',
                                                data: {
                                                    labels: ['2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018'],
                                                    datasets: [
                                                        {
                                                            label: 'NORR',
                                                            data: [null, null, null, null, null, null, 0.05881724401250035, 0.09707206959800872],
                                                            type: 'bar',
                                                            color: '#1f78b4',
                                                        },
                                                        {
                                                            label: 'Threshold',
                                                            data: [0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04],
                                                            type: 'line',
                                                            fill: false,
                                                            color: '#ff7f00',
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    };
                } else if (dashboardId === 'financial') {
                    return {
                        isFake: true,
                        _id: 'financial',
                        title: 'Financial Dashboard',
                        pages: [
                            {
                                title: 'Income Statement',
                                rows: [
                                    {
                                        itemCount: 2,
                                        height: 'medium',
                                        style: {
                                            height: 'medium',
                                        },
                                        items: [
                                            {
                                                _id: '5bf42f9e52239754a7da0b5d',
                                                type: 'graph',
                                                title: 'Net Tuition Revenue',
                                                subtitle:
                                                    'Net tuition revenue is gross tuition and fees less all forms of financial aid (need-based/merit/tuition remission). Net tuition revenue should show an upward trend over time.<br /><b>Reference point is median NTR of all schools in BIIS.</b>',
                                                format: 'integer',
                                                decimalPlaces: 2,
                                                unit: 'raw',
                                                chartType: 'line',
                                                min: 0,
                                                data: {
                                                    labels: ['2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018'],
                                                    datasets: [
                                                        {
                                                            label: 'Tuit & Fees',
                                                            data: [20544980, 21139961, 22091088, 23790846, 23583066, 23626820, 23687016, 24670361],
                                                            fill: false,
                                                            color: '#1f78b4',
                                                        },
                                                        {
                                                            label: 'NTR',
                                                            data: [13981602, 14251535, 15036473, 16295836, 15741988, 15662794, 16448862, 17768895],
                                                            fill: false,
                                                            color: '#33a02c',
                                                        },
                                                        {
                                                            label: 'Med NTR BIIS',
                                                            data: [9428749.5, 9874802.5, 10271254.333333332, 10972376, 10543459, 10716144.333333332, 11077951, 12090631],
                                                            fill: false,
                                                            color: '#ff7f00',
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                _id: '5bf4717770e8b1247168c142',
                                                type: 'graph',
                                                title: 'Total Enrollment with Financial Aid',
                                                subtitle:
                                                    'The total number of students enrolled segregated into full pay, financial aid and tuition remission students. Trend is more important than total number of students.',
                                                format: 'integer',
                                                decimalPlaces: 2,
                                                unit: 'raw',
                                                chartType: 'stacked-bar',
                                                min: 0,
                                                data: {
                                                    labels: ['2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018'],
                                                    datasets: [
                                                        {
                                                            label: 'Full Pay Stdts',
                                                            data: [null, null, null, null, null, null, 229, 235],
                                                            operators: ['-', '-', '-'],
                                                            fill: false,
                                                            color: '#1f78b4',
                                                        },
                                                        {
                                                            label: 'FA Stdts',
                                                            data: [null, null, null, null, null, null, 222, 220],
                                                            operators: ['+'],
                                                            fill: false,
                                                            color: '#ff7f00',
                                                        },
                                                        {
                                                            label: 'TR Stdts',
                                                            data: [0, 0, 0, 0, 0, 0, 35, 36],
                                                            fill: false,
                                                            color: '#6a3d9a',
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        itemCount: 2,
                                        height: 'medium',
                                        items: [
                                            {
                                                _id: '5bf493b6812defbab630e67c',
                                                type: 'graph',
                                                title: 'Operating Income',
                                                subtitle: 'Operating income includes net tuition revenue, unrestricted philanthropic support, auxiliary revenue and draws from endowment or reserves.',
                                                format: 'float',
                                                decimalPlaces: 2,
                                                unit: 'raw',
                                                chartType: 'stacked-bar',
                                                min: 0,
                                                data: {
                                                    labels: ['2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018'],
                                                    datasets: [
                                                        {
                                                            label: 'Net Tuition Revenue',
                                                            data: [null, null, null, null, null, null, 0.8299905430482898, 0.8048176878397136],
                                                            fill: false,
                                                            color: '#1f78b4',
                                                            operators: [['-', '*', '-', '*'], '/'],
                                                        },
                                                        {
                                                            label: 'Endowment Draw',
                                                            data: [null, null, null, null, null, null, 0.09040469302708118, 0.09365450287310084],
                                                            operators: ['/'],
                                                            fill: false,
                                                            color: '#4b93c3',
                                                        },
                                                        {
                                                            label: 'Annual Fund',
                                                            data: [null, null, null, null, null, null, 0.05166325913601159, 0.05282017135734005],
                                                            operators: ['/'],
                                                            fill: false,
                                                            color: '#78aed2',
                                                        },
                                                        {
                                                            label: 'Other',
                                                            data: [null, null, null, null, null, null, 0.027941504788617455, 0.04870763792984556],
                                                            operators: ['-', [['-', '*', '-', '*', '+', '+'], '/']],
                                                            fill: false,
                                                            color: '#a5c9e1',
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                _id: '5bf498f4812defbab630e67d',
                                                type: 'graph',
                                                title: 'Operating Expenses',
                                                subtitle: 'Operating expenses include salaries, benefits, instruction/program, auxiliary, facility and other expenses.',
                                                format: 'float',
                                                decimalPlaces: 2,
                                                unit: 'raw',
                                                chartType: 'stacked-bar',
                                                min: 0,
                                                data: {
                                                    labels: ['2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018'],
                                                    datasets: [
                                                        {
                                                            label: 'Payroll & Benefits',
                                                            data: [null, null, null, null, null, null, 0.5943216218420074, 0.5998359483532932],
                                                            fill: false,
                                                            operators: ['/'],
                                                            color: '#C03634',
                                                        },
                                                        {
                                                            label: 'Instructional Expenses',
                                                            data: [null, null, null, null, null, null, 0.08179197972654594, 0.08455567599014714],
                                                            operators: ['/'],
                                                            fill: false,
                                                            color: '#cc5e5c',
                                                        },
                                                        {
                                                            label: 'Administrative',
                                                            data: [null, null, null, null, null, null, 0.09430436566467616, 0.0849889625553262],
                                                            operators: ['/'],
                                                            fill: false,
                                                            color: '#d98685',
                                                        },
                                                        {
                                                            label: 'Facilities',
                                                            data: [null, null, null, null, null, null, 0.0654020819958171, 0.06498941631360165],
                                                            operators: ['/'],
                                                            fill: false,
                                                            color: '#e5aead',
                                                        },
                                                        {
                                                            label: 'Other',
                                                            data: [null, null, null, null, null, null, 0.16417995077095338, 0.1656299967876318],
                                                            operators: ['-', [['+', '+', '+'], '/']],
                                                            fill: 'false',
                                                            color: '#e5aead',
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        itemCount: 2,
                                        height: 'medium',
                                        items: [
                                            {
                                                _id: '5c335d64fb14d35e229a753e',
                                                type: 'graph',
                                                title: 'Staffing: Per Student Ratios',
                                                subtitle:
                                                    'Student-to-faculty and -to-all employees ratios are a measure of the cost and efficiency of delivering your program.<br/><b>Reference point is median for all schools in BIIS</b>',
                                                format: 'float',
                                                decimalPlaces: 2,
                                                unit: 'raw',
                                                chartType: 'bar',
                                                min: 0,
                                                data: {
                                                    labels: ['2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018'],
                                                    datasets: [
                                                        {
                                                            label: 'Student/Faculty',
                                                            data: [null, null, null, null, null, null, 6.657534246575342, 7.220588235294118],
                                                            fill: false,
                                                            operators: ['/'],
                                                            color: '#1f78b4',
                                                        },
                                                        {
                                                            label: 'Students/All Employees',
                                                            data: [null, 2.5142857142857142, null, null, null, null, 2.627027027027027, 2.727777777777778],
                                                            operators: ['/'],
                                                            fill: false,
                                                            color: '#ff7f00',
                                                        },
                                                        {
                                                            label: 'Median Student/Faculty',
                                                            data: [null, null, null, null, null, null, 7.917208114626188, 7.26468202141184],
                                                            fill: false,
                                                            modifier: 'p-50',
                                                            operators: ['/'],
                                                            color: '#33a02c',
                                                        },
                                                        {
                                                            label: 'Median Students/All Employees',
                                                            data: [null, 5.076923076923077, 5.1395348837209305, null, 4.875428556086451, null, 4.88822652757079, 4.55976430976431],
                                                            operators: ['/'],
                                                            fill: false,
                                                            modifier: 'p-50',
                                                            color: '#6a3d9a',
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                _id: '5c33675670fd25d6cfce8e73',
                                                type: 'graph',
                                                title: 'Annual Giving Per Student',
                                                subtitle: 'This is a measure of parent and alumni satisfaction with the school.<br/><b>Reference point is median AG/stdt of all schools in BIIS</b>',
                                                format: 'float',
                                                decimalPlaces: 2,
                                                unit: 'raw',
                                                chartType: 'mixed',
                                                min: 0,
                                                data: {
                                                    labels: ['2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018'],
                                                    datasets: [
                                                        {
                                                            label: 'Annual Giving/Student',
                                                            data: [
                                                                2362.9498069498068, 2318.748106060606, 2310.802559414991, 2157.4185606060605, 2490.5132575757575, 2957.8543307086616, 2874.028806584362,
                                                                3121.5437881873727,
                                                            ],
                                                            fill: false,
                                                            operators: ['/'],
                                                            color: '#1f78b4',
                                                            type: 'bar',
                                                        },
                                                        {
                                                            label: 'Median',
                                                            data: [
                                                                1261.3670398671773, 1302.3244318078569, 1270.6780142225637, 1264.2794916120824, 1452.765588945286, 1404.15926477536, 1252.9736128069462,
                                                                1211.7722095671982,
                                                            ],
                                                            modifier: 'p-50',
                                                            fill: false,
                                                            operators: ['/'],
                                                            color: '#ff7f00',
                                                            type: 'line',
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                title: 'Balance Sheet',
                                rows: [
                                    {
                                        itemCount: 2,
                                        height: 'medium',
                                        items: [
                                            {
                                                _id: '5c3381d870fd25d6cfce8e74',
                                                type: 'graph',
                                                title: 'Endowment and Debt per Student',
                                                subtitle:
                                                    "Market value of the school's endowment (corpus plus earnings) divided by enrollment. The amount of debt divided by enrollment.<br/><b>Reference point is median for all schools in BIIS</b>",
                                                format: 'integer',
                                                unit: 'dollar',
                                                chartType: 'mixed',
                                                min: 0,
                                                data: {
                                                    labels: ['2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018'],
                                                    datasets: [
                                                        {
                                                            label: 'Debt/Student',
                                                            type: 'bar',
                                                            data: [
                                                                51810.274131274135, 50111.55303030303, 47650.96526508227, 48297.833333333336, 47192.10984848485, 48530.34645669291, 49968.35390946502,
                                                                48627.74949083503,
                                                            ],
                                                            fill: false,
                                                            operators: ['/'],
                                                            color: '#1f78b4',
                                                        },
                                                        {
                                                            label: 'Endowment/Student',
                                                            data: [null, 69287.87878787878, null, null, null, null, 76997.13991769547, 74658.71283095723],
                                                            fill: false,
                                                            type: 'bar',
                                                            operators: ['/'],
                                                            color: '#33a02c',
                                                        },
                                                        {
                                                            label: 'Median Debt/Student',
                                                            data: [
                                                                14039.402341438421, 14179.144838212635, 15975.541204126943, 13703.847826086956, 13813.602475777838, 14833.382166338906,
                                                                14580.50405616062, 13997.127272727274,
                                                            ],
                                                            type: 'line',
                                                            modifier: 'p-50',
                                                            fill: false,
                                                            operators: ['/'],
                                                            color: '#ff7f00',
                                                        },
                                                        {
                                                            label: 'Median Endowment/Student',
                                                            data: [null, 14693.1703758984, 17620.598063576384, 21861.8, 13843.576203825656, null, 14655.841417629083, 11548.545454545454],
                                                            modifier: 'p-50',
                                                            type: 'line',
                                                            fill: false,
                                                            operators: ['/'],
                                                            color: '#888888',
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                _id: '5c33832f70fd25d6cfce8e75',
                                                type: 'graph',
                                                title: 'Net Assets Per Student',
                                                subtitle:
                                                    'Total net assets consist of assets minus liabilities. Total net assets give a general sense of the institution’s overall financial value.<br/><b>Reference point is median for all schools in BIIS</b>',
                                                format: 'integer',
                                                unit: 'dollar',
                                                chartType: 'mixed',
                                                min: 0,
                                                data: {
                                                    labels: ['2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018'],
                                                    datasets: [
                                                        {
                                                            label: 'Net Assets/Student',
                                                            type: 'bar',
                                                            data: [
                                                                83171.67181467182, 73243.71212121213, 76970.04021937842, 89499.73863636363, 83762.16477272728, 76887.55511811023, 92116.28189300411,
                                                                98059.10386965377,
                                                            ],
                                                            fill: false,
                                                            operators: ['/'],
                                                            color: '#1f78b4',
                                                        },
                                                        {
                                                            label: 'Median Net Assets/Student',
                                                            data: [
                                                                14039.402341438421, 14179.144838212635, 15975.541204126943, 13703.847826086956, 13813.602475777838, 14833.382166338906,
                                                                14580.50405616062, 13997.127272727274,
                                                            ],
                                                            type: 'line',
                                                            modifier: 'p-50',
                                                            fill: false,
                                                            operators: ['/'],
                                                            color: '#ff7f00',
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        itemCount: 2,
                                        height: 'medium',
                                        items: [
                                            {
                                                _id: '5c33c8408825dbc1ff021226',
                                                type: 'graph',
                                                title: 'Plant Reserves (PPRRSM)',
                                                subtitle:
                                                    "Defined as reserves set aside to cover capital maintenance and plant renewal projects, this is a measure of the school's ability to preserve and enhance its largest asset.<br/><b>Minimum target is one times annual depreciation</b>",
                                                format: 'integer',
                                                unit: 'raw',
                                                chartType: 'mixed',
                                                min: 0,
                                                data: {
                                                    labels: ['2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018'],
                                                    datasets: [
                                                        {
                                                            label: 'Plant Reserves',
                                                            type: 'bar',
                                                            data: [null, null, null, null, null, null, null, 33768],
                                                            fill: false,
                                                            operators: [],
                                                            color: '#1f78b4',
                                                        },
                                                        {
                                                            label: 'Annual Depr Exp',
                                                            data: [2231189, 2419651, 2355234, 2405195, 2496979, 2545686, 2226834, 2306621],
                                                            type: 'line',
                                                            fill: false,
                                                            operators: [],
                                                            color: '#ff7f00',
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                _id: '5c3385d370fd25d6cfce8e77',
                                                type: 'graph',
                                                title: 'Age of Plant (in years)',
                                                subtitle:
                                                    'The age of plant is a measure of deferred maintenance issues. It is total accumulated depreciation divided by depreciation expense.<br/><b>Target ceiling is 15 years</b>',
                                                format: 'integer',
                                                unit: 'dollar',
                                                chartType: 'mixed',
                                                min: 0,
                                                data: {
                                                    labels: ['2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018'],
                                                    datasets: [
                                                        {
                                                            label: 'Age of Plant',
                                                            type: 'bar',
                                                            data: [
                                                                14.513784354440615, 14.360528853127992, 12.2568059054854, 12.961486282816987, 13.47945056806645, 13.564724793238444, 16.482580650376274,
                                                                16.34838016301768,
                                                            ],
                                                            fill: false,
                                                            operators: [],
                                                            color: '#1f78b4',
                                                        },
                                                        {
                                                            label: 'Target',
                                                            data: [15, 15, 15, 15, 15, 15, 15, 15],
                                                            type: 'line',
                                                            fill: false,
                                                            operators: [],
                                                            color: '#ff7f00',
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        itemCount: 2,
                                        height: 'medium',
                                        items: [
                                            {
                                                _id: '5c33d6a066b1c76dfbdc9743',
                                                type: 'graph',
                                                title: 'Working Capital',
                                                subtitle:
                                                    'Working capital is a measure of the ability to cover liablities. The calculation is: Current Assets / Current Liabilities.<br/><b>Threshold is one times its current liabilities</b>',
                                                format: 'integer',
                                                unit: 'raw',
                                                chartType: 'mixed',
                                                min: 0,
                                                data: {
                                                    labels: ['2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018'],
                                                    datasets: [
                                                        {
                                                            label: 'Working Capital',
                                                            type: 'bar',
                                                            data: [null, null, null, null, null, null, null, 3.4645621357127436],
                                                            fill: false,
                                                            operators: ['/'],
                                                            color: '#1f78b4',
                                                        },
                                                        {
                                                            label: 'Target',
                                                            data: [1, 1, 1, 1, 1, 1, 1, 1],
                                                            type: 'line',
                                                            fill: false,
                                                            operators: [],
                                                            color: '#ff7f00',
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                _id: '5c339ab670fd25d6cfce8e79',
                                                type: 'graph',
                                                title: 'Debt Service Ratio',
                                                subtitle: "A measure of the school's ability to cover its debt obligations<br/><b>Threshold is set by loan covenants (Ex. 1.25)</b>",
                                                format: 'float',
                                                decimalPlaces: 2,
                                                unit: 'raw',
                                                chartType: 'mixed',
                                                min: 0,
                                                data: {
                                                    labels: ['2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018'],
                                                    datasets: [
                                                        {
                                                            label: 'Debt Service Ratio',
                                                            type: 'bar',
                                                            data: [null, null, null, null, null, null, 4, 3],
                                                            fill: false,
                                                            operators: [['-', '+', '+'], '/', ['+']],
                                                            color: '#1f78b4',
                                                        },
                                                        {
                                                            label: 'Target',
                                                            data: [1.25, 1.25, 1.25, 1.25, 1.25, 1.25, 1.25, 1.25],
                                                            type: 'line',
                                                            fill: false,
                                                            operators: [],
                                                            color: '#ff7f00',
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    };
                } else {
                    return {
                        isFake: true,
                        _id: 'nysais-financial',
                        title: 'NYSAIS Financial Dashboard',
                        pages: [],
                    };
                }
            }
            throw err;
        });
};

Dashboards.updateDashboardOrder = (items: Array<?string>, dashboardId: string) => {
    return axios
        .put(
            Constants.apiBaseUrl + 'dashboards/' + dashboardId + '/items/order',
            {
                items: items,
            },
            { withCredentials: true },
        )
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No Dashboard returned from Api');
            }
            return response.data;
        });
};

Dashboards.deleteDashboardItem = (itemId: string, dashboardId: string) => {
    return axios.delete(Constants.apiBaseUrl + 'dashboards/' + dashboardId + '/items/' + itemId, { withCredentials: true }).then(function (response) {
        if (!response || !response.status || response.status >= 400) {
            throw new Error('No Dashboard returned from Api');
        }
        return response.data;
    });
};

Dashboards.addDashboardItem = (dashboardId: string, newItem: any) => {
    return axios.post(Constants.apiBaseUrl + 'dashboards/' + dashboardId + '/items', newItem, { withCredentials: true }).then(function (response) {
        if (!response || !response.status || response.status >= 400) {
            throw new Error('No Dashboard returned from Api');
        }
        return response.data;
    });
};

Dashboards.updateDashboardItem = (itemId: string, dashboardId: string, updatedObj: any) => {
    return axios
        .put(Constants.apiBaseUrl + 'dashboards/' + dashboardId + '/items/' + itemId, updatedObj, {
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No Dashboard returned from Api');
            }
            return response.data;
        });
};

Dashboards.downloadPowerpointSlides = (payload: Array<PowerpointType>) => {
    return axios.post(Constants.apiBaseUrl + 'dashboards/ppt', { slides: payload }, { responseType: 'blob', withCredentials: true }).then(function (response) {
        if (!response || !response.status || response.status >= 400) {
            throw new Error('No File returned from Api');
        }
        return response.data;
    });
};

Dashboards.downloadPowerpointSlidesHighcharts = (payload: Array<PowerpointType>) => {
    return axios.post(Constants.apiBaseUrl + 'dashboards/ppthc', { slides: payload }, { responseType: 'blob', withCredentials: true }).then(function (response) {
        if (!response || !response.status || response.status >= 400) {
            throw new Error('No File returned from Api');
        }
        return response.data;
    });
};

export default Dashboards;
