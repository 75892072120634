// @flow
'use strict';

import parser from 'html-react-parser';
import { Card, CardHeader, CardContent, CardMedia } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import YouTube from 'react-youtube';
import ChartComp from '../ChartComp';
import styles from './userDashboardItem.scss';

const useStyles = makeStyles(() => ({
    card: {
        height: '100%',
        width: '100%',
        textAlign: 'center',
    },
    announcementCard: {
        height: '100%',
        width: '100%',
    },
    image: {
        objectFit: 'scale-down',
    },
}));
type Props = {|
    item: UserDashboardItemType,
|};

export function DashboardItem(props: Props) {
    const classes = useStyles();

    const renderNumericComponent: (item: UserDashboardItemNumericType) => React$Element<*> = (item) => {
        let number = item.number;

        if (typeof item.number === 'number') {
            number = '' + number;
        }
        const comp = (
            <Card className={classes.card}>
                <CardHeader title={item.title} />
                <CardContent>
                    <div key={item._id + '-2'} className={styles.numericItemNumber}>
                        <p>{number}</p>
                    </div>
                    <div key={item._id + '-3'} className={styles.numericItemText}>
                        <p>{item.text}</p>
                    </div>
                </CardContent>
            </Card>
        );
        return comp;
    };

    const renderTextComponent: (item: UserDashboardItemTextType) => React$Element<*> = (item) => {
        const comp = (
            <Card raised className={classes.card}>
                <CardHeader title={item.title} />
                <CardContent>
                    <div className={styles.textItemText} key={item._id + '-2'}>
                        {parser(item.text)}
                    </div>
                </CardContent>
            </Card>
        );
        return comp;
    };

    const renderAnnouncementComponent: (item: UserDashboardItemTextType) => React$Element<*> = (item) => {
        const comp = (
            <Card raised className={classes.announcementCard}>
                <CardContent>
                    <div style={{ fontSize: '1.5em', textDecoration: 'underline', marginBottom: '2.5rem' }}>{item.title}</div>
                    <div className={styles.textItemText} key={item._id + '-2'}>
                        {parser(item.text)}
                    </div>
                </CardContent>
            </Card>
        );
        return comp;
    };

    const renderImageComponent: (item: UserDashboardItemImageType) => React$Element<*> = (item) => {
        const comp = (
            <Card className={classes.card}>
                {/* <CardHeader title={item.text} /> */}
                <CardMedia component='img' height={item.text ? '80%' : '100%'} src={item.imageUrl} className={classes.image} />
                <CardContent>
                    <div key={item._id + '-2'} className={styles.imageItemText}>
                        <p>{item.text}</p>
                    </div>
                </CardContent>
            </Card>
        );
        return comp;
    };

    const renderGraphComponent: (item: UserDashboardItemGraphType) => React$Element<*> = (item) => {
        const comp = (
            <Card className={classes.card}>
                <ChartComp
                    key='graphItem'
                    className={styles.graphItemGraph}
                    chartType={item.chartType}
                    unit={'dollar'}
                    data={item.data}
                    // $FlowFixMe
                />
                ,
                <div key={item._id} className={styles.graphItemText}>
                    {item.text}
                </div>
            </Card>
        );
        return comp;
    };

    const renderVideoComponent: (item: UserDashboardItemImageType) => React$Element<*> = (item) => {
        const comp = (
            <Card className={classes.card}>
                {/* <CardHeader title={item.text} /> */}
                {/* <CardMedia component='video' height={item.text ? '80%' : '100%'} src={item.videoUrl} className={classes.image} /> */}
                <CardContent>
                    <YouTube height={item.text ? '80%' : '100%'} videoId={item.youtubeId} className={classes.image} containerClassName={classes.image} opts={{ height: '288', width: '512' }} />
                    <div key={item._id + '-2'} className={styles.imageItemText}>
                        <p>{item.text}</p>
                    </div>
                </CardContent>
            </Card>
        );
        return comp;
    };

    const renderComponent: () => React$Element<*> = () => {
        const item = props.item;
        if (item.type === 'numeric' || item.type === 'number') {
            return renderNumericComponent(item);
        }
        if (item.type === 'text') {
            return renderTextComponent(item);
        }
        if (item.type === 'announcement') {
            return renderAnnouncementComponent(item);
        }
        if (item.type === 'image') {
            return renderImageComponent(item);
        }
        if (item.type === 'graph1') {
            return renderGraphComponent(item);
        }
        if (item.type === 'video') {
            return renderVideoComponent(item);
        }
        return <div />;
    };

    const _renderComponent: () => React$Element<any> = () => {
        const comp = renderComponent();
        if (props.item.link) {
            return (
                <a href={props.item.link} className={styles.mainLink}>
                    {comp}
                </a>
            );
        }
        return comp;
    };
    const comp = _renderComponent();
    return <div className={styles.baseDashboardItem}>{comp}</div>;
}

export default DashboardItem;
