// @flow
'use strict';

import React from 'react';
import { Typography } from '@mui/material';

import QuickLink from '../QuickLink';
import { StyledHomePageTyography } from '../../views/UserHomepage';

type PropTypes = {|
    links: any[],
    isLoading: Boolean,
|};

export default function QuickLinks(props: PropTypes) {
    const { isLoading, links } = props;

    if (isLoading) return <div style={{ height: '400px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{/* <CircularProgress size={64} /> */}</div>;
    if (!links) return <Typography>No Links Found.</Typography>;

    return (
        <>
            <StyledHomePageTyography>{'Quick Links'}</StyledHomePageTyography>
            <div style={{ display: 'flex', flexDirection: 'column', maxHeight: '400px' }}>
                {links.map((link, index) => {
                    return (
                        <div style={{ flex: '1' }} key={index}>
                            <QuickLink link={link} />
                        </div>
                    );
                })}
            </div>
        </>
    );
}
