// @flow
'use strict';

import React from 'react';
import { Typography } from '@mui/material';
import parser from 'html-react-parser';
import { StyledHomePageTyography } from '../../views/UserHomepage';

type PropTypes = {|
    announcements: any,
    isLoading: Boolean,
|};

export default function Announcements(props: PropTypes) {
    const { announcements, isLoading } = props;

    if (isLoading) return <div style={{ height: '400px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{/* <CircularProgress size={64} /> */}</div>;
    if (!announcements) return <></>;
    return (
        <>
            <StyledHomePageTyography>{'Announcements'}</StyledHomePageTyography>
            <div style={{ display: 'flex', flexDirection: 'column', maxHeight: '400px' }}>
                {announcements.map((announcement) => {
                    return (
                        <div key={announcement._id}>
                            <Typography fontWeight={'bold'} fontSize={'0.8rem'}>
                                {announcement.title}
                            </Typography>
                            <Typography fontSize={'0.8rem'}>{parser(announcement.text)}</Typography>
                        </div>
                    );
                })}
            </div>
        </>
    );
}
