// @flow
'use strict';

import { AppBar, Toolbar, IconButton, Tooltip, ButtonBase, Typography } from '@material-ui/core';
import { SaveAlt, AddCircleOutline } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import FilterSelect from '../FilterSelect-reports';
import FilterSearch from '../FilterSearch';
import FilterModal from '../FilterModal';
import { filterOrder } from '../../views/Reports/methods';
import { useEffect, useState } from 'react';
import { budgetFilters } from '../PeergroupSchools/methods';

type Props = {|
    filters: Array<ReportFilterType>,
    selected: SelectedReportFilterType,
    topics?: ?Array<ReportTopicType>,
    // selectedReportTopicId?: ?string,
    handleFilterChange: (id: $Keys<SelectedReportFilterType>, value: string) => void,
    handleSearch?: (event: SyntheticInputEvent<*>) => void,
    handleDownloadButton?: (event: SyntheticInputEvent<*>) => void,
    showDownloadButton?: boolean,
    showNewButton?: boolean,
    handleNewButton?: () => void,
    handleBudgetFilter?: () => void,
    showBudget?: boolean,
    showEndowment?: boolean,
|};

const useStyles = makeStyles((t = { palette: { primary: {} } }) => ({
    appBar: {
        background: 'none',
        width: '100%',
    },
    toolBar: {
        background: 'none',
        width: '100%',
    },
    primaryItem: {
        flexGrow: 2,
    },
    secondaryItem: {
        flexGrow: 1,
    },
    filterBarItem: {
        marginLeft: 2,
    },
    download: {
        backgroundColor: t.palette?.primary?.main || 'inherit',
        color: 'white',
    },
    newButton: {
        backgroundColor: t.palette?.primary?.main || 'inherit',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
    },
    newButtonIcon: {
        display: 'block',
        fontSize: 18,
    },
    newButtonLabel: {
        fontSize: 12,
        margin: '0 5px 0 5px',
    },
}));

export function GenericFilterBar(props: Props) {
    const [selectedBudgetValue, setSelectedBudgetValue] = useState([]);
    const {
        filters,
        selected,
        handleFilterChange,
        handleSearch = () => undefined,
        handleDownloadButton = () => undefined,
        showDownloadButton,
        showNewButton,
        handleNewButton = () => undefined,
        topics,
        handleBudgetFilter,
        showBudget,
        showEndowment,

        // selectedReportTopicId = null,
    } = props;

    useEffect(() => {
        if (selected) setSelectedBudgetValue(selected.budget || []);
    }, [props.selected]);

    const classes = useStyles();
    const renderSingleItem: (filterItem: ReportFilterType, selectedValue: ?string | string[], index: number) => React$Element<*> = (filterItem, selectedValue, index) => {
        let className = classes.filterBarItem;
        let value = filterItem.multiSelect ? [] : '';
        if (filterItem.importance === 'primary') {
            className += ' ' + classes.primaryItem;
        }
        if (filterItem.importance === 'secondary') {
            className += ' ' + classes.secondaryItem;
        }

        const id = `jr-${filterItem._id}wrapper`;
        if (filterItem.type === 'search') {
            return <FilterSearch id={id} key={filterItem._id + '-key-' + index} className={className} filterItem={filterItem} value={selectedValue} handleSearch={handleSearch} />;
        }
        if (filterItem.type === 'modal') {
            return <FilterModal id={id} key={filterItem._id + '-key-' + index} className={className} filterItem={filterItem} value={selectedValue} onChange={handleFilterChange} />;
        }

        return (
            <FilterSelect
                id={id}
                disabled={false}
                className={className}
                filterItem={filterItem}
                key={filterItem._id + '-key-' + index}
                value={selectedValue || value}
                onChange={handleFilterChange}
                selected={selected}
                topics={topics}
            />
        );
    };

    const renderItems: (filters: ReportFilterType[]) => Array<React$Element<*>> = (filters) => {
        const items = !showEndowment ? filters.filter((f) => f._id !== 'endowments') : filters;
        return items
            .filter((f) => f._id !== 'topic')
            .sort((a, b) => {
                const orderA = filterOrder.find((o) => a._id === o._id);
                const orderB = filterOrder.find((o) => b._id === o._id);

                if (!orderA || !orderB) return 0;
                if (!orderA.order || !orderB.order) return 0;
                return orderA.order - orderB.order;
            })
            .map(function (filter, index) {
                const filterA = filterOrder.find((o) => filter._id === o._id);

                return renderSingleItem({ ...filter, multiSelect: filterA ? filterA.multiSelect : false }, selected[filter._id], index);
            })
            .filter((f) => f);
    };

    return (
        <AppBar position='relative' className={classes.appBar}>
            <Toolbar className={classes.toolBar} style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                {!showNewButton ? null : (
                    <Tooltip placement='bottom' title={'Create New Peergroup'}>
                        <ButtonBase id='jr-newpeergroupbutton' focusRipple className={classes.newButton} onClick={handleNewButton} variant='contained'>
                            <AddCircleOutline className={classes.newButtonIcon} />
                            <Typography className={classes.newButtonLabel}>New Group</Typography>
                        </ButtonBase>
                    </Tooltip>
                )}
                {renderItems(filters.filter((f) => f._id !== 'colaIndex'))}

                {showBudget && (
                    <FilterSelect
                        id={'budget'}
                        disabled={false}
                        className={classes.filterBarItem}
                        filterItem={{
                            _id: 'budget',
                            name: 'Budget',
                            choices: budgetFilters,
                            multiSelect: true,
                        }}
                        value={selectedBudgetValue}
                        onChange={(name, value) => {
                            setSelectedBudgetValue(value);
                            if (handleBudgetFilter) handleBudgetFilter(value);
                        }}
                    />
                )}
                {!showDownloadButton ? null : (
                    <Tooltip placement='bottom' title={'Download Data'}>
                        <IconButton className={classes.download} onClick={handleDownloadButton}>
                            <SaveAlt className={classes.download} />
                        </IconButton>
                    </Tooltip>
                )}
            </Toolbar>
        </AppBar>
    );
}

export default GenericFilterBar;
