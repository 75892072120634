export const initialState = {
    announcements: null,
    dataEntryStatus: null,
    quickLinks: null,
    pinnedCharts: null,
    items: null,
    isHomepageLoading: false,
};

export const HOMEPAGE_ACTION_TYPES = {
    SET_ANNOUNCEMENTS: 'SET_ANNOUNCEMENTS',
    SET_DATA_ENTRY_STATUS: 'SET_DATA_ENTRY_STATUS',
    SET_PINNED_CHARTS: 'SET_PINNED_CHARTS',
    SET_QUICK_LINKS: 'SET_QUICK_LINKS',
    SET_HOMEPAGE_ITEMS: 'SET_HOMEPAGE_ITEMS',
    SET_IS_HOMEPAGE_LOADING: 'SET_IS_HOMEPAGE_LOADING',
    ADD_PINNED_CHART: 'ADD_PINNED_CHART',
    REMOVE_PINNED_CHART: 'REMOVE_PINNED_CHART',
};

export const setIsHomepageLoading = (param) => ({
    type: HOMEPAGE_ACTION_TYPES.SET_IS_HOMEPAGE_LOADING,
    payload: param,
});
export const setAnnouncements = (param) => ({
    type: HOMEPAGE_ACTION_TYPES.SET_ANNOUNCEMENTS,
    payload: param,
});
export const setDataEntryStatus = (param) => ({
    type: HOMEPAGE_ACTION_TYPES.SET_DATA_ENTRY_STATUS,
    payload: param,
});
export const setPinnedCharts = (param) => ({
    type: HOMEPAGE_ACTION_TYPES.SET_PINNED_CHARTS,
    payload: param,
});
export const setQuickLinks = (param) => ({
    type: HOMEPAGE_ACTION_TYPES.SET_QUICK_LINKS,
    payload: param,
});
export const setHomepageItems = (param) => ({
    type: HOMEPAGE_ACTION_TYPES.SET_HOMEPAGE_ITEMS,
    payload: param,
});
export const addPinnedChart = (param) => ({
    type: HOMEPAGE_ACTION_TYPES.ADD_PINNED_CHART,
    payload: param,
});
export const removePinnedCharts = (param) => ({
    type: HOMEPAGE_ACTION_TYPES.REMOVE_PINNED_CHART,
    payload: param,
});

const homepageReducer = (state, action) => {
    const newState = { ...state };
    switch (action.type) {
        case HOMEPAGE_ACTION_TYPES.SET_IS_HOMEPAGE_LOADING:
            newState.isHomepageLoading = action.payload;
            return newState;
        case HOMEPAGE_ACTION_TYPES.SET_ANNOUNCEMENTS:
            newState.announcements = action.payload;
            return newState;
        case HOMEPAGE_ACTION_TYPES.SET_DATA_ENTRY_STATUS:
            newState.dataEntryStatus = action.payload;
            return newState;
        case HOMEPAGE_ACTION_TYPES.SET_PINNED_CHARTS:
            newState.pinnedCharts = action.payload;
            return newState;
        case HOMEPAGE_ACTION_TYPES.SET_QUICK_LINKS:
            newState.quickLinks = action.payload;
            return newState;
        case HOMEPAGE_ACTION_TYPES.SET_HOMEPAGE_ITEMS:
            newState.items = action.payload;
            return newState;
        case HOMEPAGE_ACTION_TYPES.ADD_PINNED_CHART:
            newState.pinnedCharts = [...state.pinnedCharts, action.payload];
            return newState;
        case HOMEPAGE_ACTION_TYPES.REMOVE_PINNED_CHART:
            newState.pinnedCharts = state.pinnedCharts.filter((entry) => entry._id !== action.payload);
            return newState;
        default:
            return state;
    }
};

export default homepageReducer;
