// @flow
'use strict';

import { useEffect } from 'react';
import { Paper, Typography } from '@material-ui/core';

import { useSelector } from 'react-redux';

import { getSelectedSchool, getUser, isLoading } from '../../redux/reducers';
import { makeStyles } from '@material-ui/styles';
import PinnedCharts from '../../components/PinnedCharts';

import { Box, styled } from '@mui/material';
import QuickLinks from '../../components/QuickLinks';
import { useHomepageContext } from '../../context/homepage';
import Announcements from '../../components/Announcements';
import HomepageItem from '../../components/HomepageItem';
import useHomepage from '../../hooks/useHomepage';

const StyledHomePageBox = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid black',
    padding: '0.5rem',
    minHeight: '300px',
    boxShadow: '2px 2px',
}));
const StyledHomePageRow = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}));
const StyledHomePageColumn = styled(Box)(() => ({
    width: '33%',
    maxWidth: '600px',
}));
export const StyledHomePageTyography = styled(Typography)(() => ({
    fontWeight: 'bold',
    fontSize: '1rem',
    paddingBottom: '0.25rem',
    color: 'black',
}));

const useStyles = makeStyles(() => ({
    paper: {
        height: '100%',
        width: '100%',
        minWidth: 1080,
        overflow: 'auto',
        margin: 0,
        padding: 0,
        background: 'none',
        flexGrow: 1,
    },
    grid: {
        justifyContent: 'center',
        width: '100%',
        margin: 0,
        padding: 0,
    },
    header: {
        background: 'rgba(0, 0, 0, 0.7)',
        height: 450,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: 'white',
        textAlign: 'center',
    },
    headerTitleWrapper: {
        marginTop: 125,
    },
    headerTitle: {
        fontWeight: 'bold',
        fontSize: 28,
    },
    headerContent: {
        '& p': {
            marginTop: 10,
            marginBottom: 10,
        },
    },
    item: {
        height: 396,
    },
}));

export function UserHomepage() {
    const classes = useStyles();

    const {
        state: { announcements, quickLinks, pinnedCharts, items, isHomepageLoading },
    } = useHomepageContext();
    const [getUserHomepage] = useHomepage();

    const gIsLoading = useSelector(isLoading);
    const user = useSelector(getUser);
    const selectedSchool = useSelector(getSelectedSchool);

    useEffect(() => {
        (async () => {
            if (selectedSchool) {
                await getUserHomepage();
            }

            // console.log('user', user);
        })();
    }, [selectedSchool, user]);

    return (
        <Paper className={classes.paper} style={{ padding: '2rem' }}>
            <div style={{ fontWeight: 'bold', fontSize: '1.5rem', paddingBottom: '.5rem' }}>{user && `Welcome, ${user.firstName}`}</div>
            <StyledHomePageRow>
                <StyledHomePageColumn>
                    <StyledHomePageBox sx={{ height: '200px' }}>
                        <Announcements announcements={announcements} isLoading={isHomepageLoading || gIsLoading} />
                    </StyledHomePageBox>
                </StyledHomePageColumn>
                <StyledHomePageColumn>
                    <StyledHomePageBox sx={{ height: '200px' }}>
                        <HomepageItem item={items ? items.find((i) => i.order === 0) : null} isLoading={isHomepageLoading || gIsLoading} />
                    </StyledHomePageBox>
                </StyledHomePageColumn>
                <StyledHomePageColumn>
                    <StyledHomePageBox sx={{ height: '200px' }}>
                        <QuickLinks links={quickLinks} isLoading={isHomepageLoading || gIsLoading} />
                    </StyledHomePageBox>
                </StyledHomePageColumn>
            </StyledHomePageRow>
            <StyledHomePageBox sx={{ marginTop: '1rem' }}>
                <div>
                    <PinnedCharts charts={pinnedCharts} isLoading={isHomepageLoading || gIsLoading} />
                </div>
            </StyledHomePageBox>
            <StyledHomePageRow>
                <StyledHomePageColumn>
                    <StyledHomePageBox sx={{ marginTop: '1rem' }}>
                        <HomepageItem item={items ? items.find((i) => i.order === 1) : null} isLoading={isHomepageLoading || gIsLoading} />
                    </StyledHomePageBox>
                </StyledHomePageColumn>
                <StyledHomePageColumn>
                    <StyledHomePageBox sx={{ marginTop: '1rem' }}>
                        <HomepageItem item={items ? items.find((i) => i.order === 2) : null} isLoading={isHomepageLoading || gIsLoading} />
                    </StyledHomePageBox>
                </StyledHomePageColumn>
                <StyledHomePageColumn>
                    <StyledHomePageBox sx={{ marginTop: '1rem' }}>
                        <HomepageItem item={items ? items.find((i) => i.order === 3) : null} isLoading={isHomepageLoading || gIsLoading} />
                    </StyledHomePageBox>
                </StyledHomePageColumn>
            </StyledHomePageRow>
        </Paper>
    );
}
