// @flow
'use strict';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

declare type ConfirmModalProps = {
    isOpen: Boolean,
    closeText?: String,
    title: String,
    hideConfirm?: boolean,
    onClose: () => void,
    onConfirm: () => void,
    confirmDisabled?: Boolean,
};
export default function ConfirmModal(props: ConfirmModalProps) {
    const { isOpen, title, onClose, onConfirm, children, hideConfirm, closeText, confirmDisabled } = props;
    return (
        <div>
            <Dialog open={isOpen} onClose={onClose}>
                <DialogTitle>{title || 'Confirm Action'}</DialogTitle>
                <DialogContent>{children || <div>Confirm Action?</div>}</DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>{closeText || 'Cancel'}</Button>
                    {!hideConfirm ? (
                        <Button disabled={confirmDisabled} onClick={onConfirm}>
                            Confirm
                        </Button>
                    ) : (
                        <></>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
}
