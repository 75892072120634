// @flow
'use strict';

import parser from 'html-react-parser';

import React from 'react';
import YouTube from 'react-youtube';
import { StyledHomePageTyography } from '../../views/UserHomepage';
type PropTypes = {|
    item: any,
    isLoading: boolean,
|};
export default function HomepageItem(props: PropTypes) {
    const { item, isLoading } = props;
    const renderItem = (item) => {
        switch (item.type) {
            case 'html':
                return renderHtmlComponent(item);
            case 'video':
                return renderVideoComponent(item);
        }
    };
    const renderHtmlComponent = (item) => {
        const comp = (
            <>
                <StyledHomePageTyography>{item.title}</StyledHomePageTyography>
                <div key={item._id + '-2'}>{parser(item.text)}</div>
            </>
        );
        return comp;
    };

    const renderVideoComponent = (item) => {
        const comp = (
            <>
                <StyledHomePageTyography>{item.title}</StyledHomePageTyography>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <YouTube videoId={item.youtubeId} opts={{ height: '169', width: '300' }} />
                </div>
            </>
        );
        return comp;
    };

    if (isLoading) return <div style={{ height: '400px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{/* <CircularProgress size={64} /> */}</div>;
    if (!item) return <></>;

    return <div style={{ height: '400px', maxHeight: '400px', overflow: 'hidden' }}>{renderItem(item)}</div>;
}
