// @flow
'use strict';

import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import { getSelectedSchool } from '../../redux/reducers';
type PropTypes = {|
    link: any,
|};

export default function QuickLink(props: PropTypes) {
    const [link, setLink] = useState(props.link);
    const selectedSchool = useSelector(getSelectedSchool);
    useEffect(() => {
        setLink(props.link);
    }, [props.link]);

    return (
        <Link to={link.url.replace('schoolId', selectedSchool && selectedSchool._id)}>
            <Typography style={{ fontSize: '0.75rem', fontWeight: 'bold' }} color='primary'>
                {link.name}
            </Typography>
        </Link>
    );
}
