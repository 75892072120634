// @flow
import React, { useReducer, useContext } from 'react';
import homepageReducer, { initialState } from './ducks/homepage';

type HomepageContextProviderProps = {
    children: any,
};

const HomepageContext = React.createContext({ state: initialState, dispatch: () => null });

const HomepageContextProvider = ({ children }: HomepageContextProviderProps) => {
    const [state, dispatch] = useReducer(homepageReducer, initialState);

    return <HomepageContext.Provider value={{ state, dispatch }}>{children}</HomepageContext.Provider>;
};

export const useHomepageContext = () => useContext(HomepageContext);

export { HomepageContextProvider };
