// @flow
'use strict';

import axios from 'axios';
import * as Utils from '../utils';
const Constants = Utils.Constants;

const Homepage = {};

Homepage.fetchUserHomepage = (schoolId): Promise<any> => {
    return axios
        .get(Constants.apiBaseUrl + 'homepage/' + schoolId, {
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No Homepage returned from Api');
            }
            return response.data;
        });
};

Homepage.addPinnedChart = (options: any): Promise<any> => {
    return axios
        .post(Constants.apiBaseUrl + 'pinnedCharts', options, {
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No Homepage returned from Api');
            }
            return response.data;
        });
};
Homepage.updateAnnouncement = (options: any): Promise<any> => {
    return axios
        .put(Constants.apiBaseUrl + 'announcments', options, {
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('Announcement Api return an error');
            }
            return response.data;
        });
};
Homepage.updateItem = (itemId: String, options: any): Promise<any> => {
    return axios
        .put(Constants.apiBaseUrl + `item/${itemId}`, options, {
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('Homepage Item Api return an error');
            }
            return response.data;
        });
};
Homepage.updateVideo = (options: any): Promise<any> => {
    return axios
        .put(Constants.apiBaseUrl + 'video', options, {
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('Video Api return an error');
            }
            return response.data;
        });
};

Homepage.deleteChart = (chartId: string): Promise<any> => {
    return axios.delete(Constants.apiBaseUrl + 'pinnedCharts/' + chartId, { withCredentials: true }).then(function (response) {
        if (!response || !response.status || response.status >= 400) {
            throw new Error('Bad response from api for deleting chart');
        }
        return response.data;
    });
};

export default Homepage;
